@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

input{
  outline:0px!important;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
strong,
input,
label,
button,
li,
a {
  font-family: 'Poppins', sans-serif;
}

#spinner {
  z-index: 100000;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  color: black;
  background-color: #fff;
  left: 0;
  display: none;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  flex-direction: column;
  section {
    display: flex;
    height: 200px;
    align-content: center;
    align-items: center;
    //justify-content: space-around;
    flex-direction: column;
    .img-logo {
      width: 140px;
      height: 23px;
    }
    .img-loader {
      margin-top: 30px;
      width: 30px;
      height: 30px;
      margin-bottom: 30px;
    }
    .h2-loader {
      font-size: 25px;
    }
    .p-loader {
      text-transform: uppercase;
      font-size: 12px;
    }
  }
}

.spinner-show {
  display: flex !important;
}

@import "~@mobiscroll/angular/dist/css/mobiscroll.scss";